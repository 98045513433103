@font-face {
  font-family: '57_futuraregular';
  src: url('assets/57futura/57futura-regular-webfont.woff2') format('woff2'),
       url('assets/57futura/57futura-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@import url('https://fonts.googleapis.com/css2?family=Alumni+Sans+Inline+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alumni+Sans+Inline+One&family=Rubik:wght@500&display=swap');

:root {
  --font-family: '57_futuraregular', Sans;

  --gradient-text: #FFFFFF;
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-inventory: #042c54;
  --color-text: #1B437E;
  --color-subtext: #FF8A71;
}
