.mmk__fteam {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    letter-spacing: 0.1rem;
}

.mmk__fteam-heading {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
    margin-right: 1rem;
    letter-spacing: 0.2rem;
}

.MuiAccordion-root p{
    font-family: 'Rubik', sans-serif; 
    font-size:18px;
    color: white;
 }

.mmk__fteam-heading img {
    margin-top: 3rem;
    max-width: 800px;
}

.mmk__fteam-heading h1 {
    font-size: 45px;
    line-height: 45px;
    font-weight: 500;
    font-family: var(--font-family);
    letter-spacing: 0.2rem;
}

.mmk__fteam-heading p {
    color: #FEE67B;
    font-size: 24px;
    line-height: 30px;
    font-weight: 400;
    font-family: var(--font-family);

    margin-top: 1rem;
    letter-spacing: 0.2rem;
    margin-bottom: 1rem;
}

.mmk__fteam-container {
    flex: 1.5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width: 990px) {
    .mmk__fteam {
        flex-direction: column;
    }

    .mmk__fteam-heading {
        margin: 0 0 2rem 0;
    }
    .mmk__fteam-heading img {
        margin-top: 3rem;
        max-width: 600px;
    }
}

@media screen and (max-width: 550px) {
    .mmk__fteam-heading {
        margin-bottom: 0;
    }
    .mmk__fteam-heading h1 {
        font-size: 20px;
        line-height: 30px;
    }
    .mmk__fteam-heading p {
        font-size: 16px;
        line-height: 22px;
        margin-top: 0;
        margin-bottom: 0.5;
    }

    .mmk__fteam-heading img {
        margin-top: 0rem;
        max-width: 300px;
    }
}