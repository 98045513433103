.mmk__whatmmk {
    display: flex;
    flex-direction: column;

    padding: 2rem;
    padding-bottom: 10rem;

}
.wave img {
    width: auto;
    position: absolute;
    z-index: 0;
}

.mmk__whatmmk-feature {
    display: flex;
}

/* Customizing feature component as per needs */
.mmk__whatmmk-feature .mmk__fteam-container__feature {
    margin: 0;
}

/* Customizing feature component as per needs */
.mmk__whatmmk-feature .mmk__fteam-container_feature-text {
    max-width: 700px;
    letter-spacing: 0.1rem;
}

.mmk__whatmmk-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 3rem;
    z-index: 1;
}

.base__title  {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: var(--font-family);
    color: white;
    margin-bottom: 0;
    letter-spacing: 0.1rem;
}

.mmk__whatmmk-heading h1 {
    font-size: 45px;
    line-height: 45px;
    font-weight: 500;
    color: white;
    font-family: var(--font-family);
    letter-spacing: 0.1rem;
    align-content: center;
    text-align: center;
}

.mmk__whatmmk-heading h2 {
    font-size: 28px;
    line-height: 35px;
    font-weight: 500;
    color: white;
    font-family: 'Rubik', sans-serif;
    letter-spacing: 0.1rem;
    text-align: left;
    text-indent: 7rem;
}

.mmk__fteam-container-img{
    max-height: 100px;
    max-width: 100px;
}

.mmk__whatmmk-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    margin-left: 2rem;
    margin-right: 2rem;
}

@keyframes onMouseEnter {
    0% {
        top: 0;
   }

   50% {
        top: -20px;
        transform: scale(1.6);
   }

   100% {
      top: 0;
      transform: scale(1.3);
   }
}

.mmk__whatmmk-container button {
    background: none;
    border: none;
    margin: 0 1rem;
}
.mmk__whatmmk-container img:hover {
    opacity: 0.8;
}

.mmk__whatmmk-container button:focus img {
    animation: onMouseEnter 0.5s forwards;
}

.mmk__whatmmk-container img {
    display: block;
    max-width: 180px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

/* Customizing feature component as per needs */
.mmk__whatmmk-container .mmk__fteam-container__feature {
    flex: 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
    letter-spacing: 0.1rem;
}

/* Customizing feature component as per needs */
.mmk__whatmmk-container .mmk__fteam-container_feature-text {
    margin-top: 0.5rem;
    letter-spacing: 0.1rem;
}

@media screen and (max-width: 850px) {
    .mmk__whatmmk {
        background-color: #F16048;
    }
    .mmk__whatmmk-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
        margin-top: 1rem;
    }

    .mmk__whatmmk-heading p {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .mmk__whatmmk-feature .mmk__fteam-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .mmk__whatmmk-feature .mmk__fteam-container_feature-text {
        margin-top: 0.5rem;
        letter-spacing: 0.1rem;

    }
    .mmk__whatmmk {
        background-color: #F16048;
        padding-bottom: 2rem;
        padding-top: 0rem;
    }
    .mmk__whatmmk-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
        margin-top: 0.5rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
    .mmk__whatmmk-heading h1 {
        font-size: 25px;
        line-height: 30px;
        letter-spacing: 0.1rem;
    }
    .mmk__whatmmk-heading h2 {
        font-size: 18px;
        line-height: 22px;

    }
    .mmk__whatmmk-container {
        margin-left: 0rem;
        margin-right: 0rem;
        padding: 0;
    }
    .mmk__whatmmk-container button {
        margin: 0;
    }
    .mmk__whatmmk-container img {
        display: block;
        max-width: 120px;
        margin: 0;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .mmk__whatmmk-container .mmk__fteam-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
}