.mmk__brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: #74CCD3;

}

.mmk__brand div {
    flex: 1;
    max-width: 220px;
    min-width: 150px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    padding: 2rem 2rem;
    min-height: 60px;
}

.mmk__brand img {
    max-height: 50px;
    width: auto;
}

@media screen and (max-width: 650px) {
    .mmk__brand {
        align-items: baseline;
        padding: 0rem;
        margin: 0rem;
    }
    
    .mmk__brand div {        
        padding: 0rem 0rem;
        height: 50px;
        margin: 0.1rem;
    }

}