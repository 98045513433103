  .Inventory {
    background: rgb(254,230,123);
    background: -moz-linear-gradient(180deg, rgba(254,230,123,1) 0%, rgba(209,238,250,1) 90%);
    background: -webkit-linear-gradient(180deg, rgba(254,230,123,1) 0%, rgba(209,238,250,1) 90%);
    background: linear-gradient(180deg, rgba(254,230,123,1) 0%, rgba(209,238,250,1) 90%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fee67b",endColorstr="#d1eefa",GradientType=1);
    display: flex;
    position: relative;
  }

 img {
    display: block;
    cursor: pointer;
  }
  
  .container {
    margin: auto;
    width: 100%;
  }
  
  .container__selected {
    margin: auto;
    float: right;
    width: 500;
    padding: 30px 30px 30px;
  }
  .container .selected {
    width: 500px;
    height: 500px;
    border-radius: 15px;
    margin: 0 auto;
    border: 4px solid purple;
  }
  
  .container .imgContainer {
    position: relative;
    float: left;

    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 30px 0 30px 30px;
    width: 25%;
  }


  .container .imgContainer img {
    width: 150px;
    height: 150px;
    margin: 5px 5px;
    border-radius: 15px;
  }
  