.mmk__header {
    display: flex;
    flex-direction: row;
}

.mmk__header-content {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    z-index: 1;
}

.mmk__header-content h1 {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 60px;
    line-height: 75px;
    letter-spacing: 0.3rem;
    margin-top: 14rem;
}

.mmk__header-content p {
    font-family: 'Rubik', sans-serif;
    font-weight: 100;
    font-size: 22px;
    line-height: 30px;
    color: white;
    letter-spacing: 0.2rem;

    margin-top: 2rem;
    margin-bottom: 2rem;
}

.mmk__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    right: -5rem;
}

.mmk__header-image img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1050px) {
    .mmk__header {
        overflow-x: hidden;
        overflow-y: hidden;
    }
    
    .mmk__header {
        flex-direction: column;
    }

    .mmk__header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .mmk__header {
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .mmk__header-content h1 {
        font-size: 40px;
        line-height: 50px;
        z-index: 1;
    }

    .mmk__header-content p {
        font-size: 16px;
        line-height: 24px;
        z-index: 1;
        color: white;
    }

    .mmk__header-image img {
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        position: absolute;
        top: -35.55rem;
        left: -14rem;
        z-index: 0;
        height: 100vh;
        width: auto;
        flex: none;
    }
}

@media screen and (max-width: 490px) {
    .mmk__header {
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .mmk__header-content h1 {
        font-size: 24px;
        line-height: 30px;
        margin-top: 8rem;
    }

    .mmk__header-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .mmk__header-image img {
        z-index: 0;
        height: 70vh;
        width: auto;
        flex: none;
        top: -22.9rem;
        left: -10rem;
        max-width: 90vw ;
        height: auto;
        display: none;
    }
}

@media screen and (max-width: 390px) {
    .mmk__header  {
        min-height: 570px;
    }
    
    .mmk__header-image img {
        /* z-index: 0;
        width: 100vw;
        top: -5.5rem;
        left: -6rem;
        position: relative; */
        display: none;
    }
    .mmk__header-content p {
        font-size: 14px;
        line-height: 24px;
    }
}