.mmk__mintpage {
  display: flex;
  flex-direction: row;
  background: rgb(254,230,123);
  background: -moz-linear-gradient(180deg, rgba(254,230,123,1) 0%, rgba(209,238,250,1) 90%);
  background: -webkit-linear-gradient(180deg, rgba(254,230,123,1) 0%, rgba(209,238,250,1) 90%);
  background: linear-gradient(180deg, rgba(254,230,123,1) 0%, rgba(209,238,250,1) 90%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fee67b",endColorstr="#d1eefa",GradientType=1);
  display: flex;
  position: relative;
}

.mmk__mintpage-content h1 {
  font-family: var(--magic__font);
  font-weight: 400;
  font-size: 60px;
  line-height: 75px;
  letter-spacing: 0.3rem;
  margin-top: 12rem;
  flex-direction: row;

}

.mmk__mintpage-content p {
  font-family: var(--font-family);
  font-weight: 100;
  font-size: 22px;
  line-height: 30px;
  color: var(--color-text);
  letter-spacing: 0.2rem;

  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mmk__mintpage-bg {
  width: max-content;
}

img {
  display: block;
  cursor: pointer;
  width: 100%;
  flex-direction: row;

}

