.mmk__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: var(--color-footer);
}

.mmk__footer-heading {
    width: 100%;
    text-align: center;

    margin-bottom: 2rem;
}

.mmk__footer-heading h1 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 44px;
    line-height: 55px;
    letter-spacing: 0.2rem;
    margin-top: 1rem;
}

.mmk__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    text-align: left;
}

.mmk__footer-links div {
    width: 250px;
    margin: 1rem;
}

.mmk__footer-links_logo {
    display: flex;
    flex-direction: column;
}

.mmk__footer-links_logo img {
    width: 118px;
    height: 50px;

    margin-bottom: 1rem;
}

.mmk__footer-links_logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 13px;
    color: #fff;
}

.mmk__footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.mmk__footer-links_div h4 {
    font-size: 15px;
    line-height: 16px;
    font-family: var(--font-family);
    color: #fff;

    margin-bottom: 0.9rem;
    letter-spacing: 0.1rem;

}

.mmk__footer-links_div p {
    font-size: 12px;
    line-height: 13px;
    font-family: var(--font-family);
    color: #fff;

    margin: 0.5rem 0;
    cursor: pointer;
    letter-spacing: 0.1rem;
}

.mmk__footer-copyright {
    margin-top: 0rem;
    text-align: center;
    width: 100%;
    letter-spacing: 0.1rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.mmk__footer-copyright p {
    font-size: 12px;
    font-family: var(--font-family);
    line-height: 15px;
    color: #fff;
}

@media screen and (max-width: 850px) {
    .mmk__footer-heading h1 {
        font-size: 34px;
        line-height: 40px;
    }
}

@media screen and (max-width: 550px) {
    .mmk__footer-heading h1 {
        font-size: 20px;
        line-height: 25px;
    }

    .mmk__footer-links div {
        margin: 1rem 0;
        flex: content;
    }
    .mmk__footer-copyright {
        padding-bottom: 0rem;
        padding-top: 0rem;
    }
    .mmk__footer-links_div h4 {
        font-size: 14px;
        line-height: 17px;
        font-family: var(--font-family);
        color: #fff;
    
        margin-bottom: 0rem;
        letter-spacing: 0rem;
    
    }
    
    .mmk__footer-links_div p {
        font-size: 10px;
        line-height: 12px;
        font-family: var(--font-family);
        color: #fff;
    
        margin: 0.5rem 0;
        cursor: pointer;
        letter-spacing: 0.1rem;
    }
    
}

@media screen and (max-width: 400px) {
    .mmk__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }

}
